import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useScript, classNames } from 'helpers';
import MapsStyle from './MapsStyle/MapsStyle';
import './Maps.scss';

const apiKey = process.env.GATSBY_GOOGLE_API_KEY;

export const Maps = props => {
  const { coordinates, zoom, children, mapsRef, className } = props;
  const [loaded, error] = useScript(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`);
  const classes = classNames('Maps', className);
  return (
    <div className={classes}>
      {loaded && (
        <GoogleMap
          zoom={zoom || 12}
          center={coordinates}
          onLoad={map => mapsRef(map)}
          options={{ styles: MapsStyle, mapTypeControl: false, fullscreenControl: false, streetViewControl: false }}
        >
          {children}
        </GoogleMap>
      )}
    </div>
  );
};
