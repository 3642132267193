import React from 'react';
import { graphql } from 'gatsby';
import { Layout, SEO } from 'components';
import { Slices } from '../slices/slices';

export const Page = (props) => {
  const { location, data } = props;
  const siteName = data && data.site && data.site.siteMetadata && data.site.siteMetadata.siteName;
  const dataSlices = data && data.prismicPage && data.prismicPage.data && data.prismicPage.data.body;
  const dataPage = data && data.prismicPage && data.prismicPage.data;
  const firstSlice = dataSlices && dataSlices[0] && dataSlices[0].slice_type;
  const isHero = firstSlice === 'hero_image' || firstSlice === 'hero_video';
  const hasForm = dataSlices && dataSlices.some((slice) => slice.slice_type === 'form');

  return (
    <Layout location={location} isHero={isHero} hasForm={hasForm}>
      <SEO
        title={`${dataPage && dataPage.title && dataPage.title.text} | ${siteName}`}
        desc={dataPage?.description?.text}
      />
      <Slices data={dataSlices} />
    </Layout>
  );
};

export default Page;

export const Query = graphql`
  query($uid: String!) {
    site {
      siteMetadata {
        siteName
      }
    }
    prismicPage(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        description {
          text
        }
        body {
          ...PageAccordion
          ...PageContentDisplay
          ...PageFeatures
          ...PageFeaturesImages
          ...PageForm
          ...PageHeroImage
          ...PageHeroVideo
          ...PageImageSlider
          ...PageImageText
          ...PageList
          ...PageProcess
          ...PageQuote
          ...PageStatistics
          ...PageText
          ...PageVideo
        }
      }
    }
  }
`;
