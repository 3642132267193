import React from 'react';
import { Link, Icon, Wrapper, Logo } from 'components';
import './Footer.scss';

const Links = ({ links, path }) => {
  const { nodes } = links;
  return (
    <div className="links">
      {nodes.map((node, index) => {
        if (node && node.uid === 'home') return null;
        const defaultTitle = node.data.title.text || node.uid;
        const title = defaultTitle === 'Articles' ? 'Blog' : defaultTitle;
        return (
          <Link key={index} to={path ? `/${path}/${node.uid}` : `/${node.uid}`}>
            {title}
          </Link>
        );
      })}
    </div>
  );
};

const SocialLinks = ({ links }) => {
  if (!links) return null;
  return (
    <div className="social-links">
      {links.map(({ name, url }) => {
        if (!name || !url) return null;
        return (
          <a className="link" target="_blank" rel="noopener noreferrer" href={url} key={name}>
            <Icon title={name} />
          </a>
        );
      })}
    </div>
  );
};

const FooterColumn = ({ content, title, path }) => {
  if (content.nodes.length < 2) return null;
  return (
    <div className="footer-column">
      <h6>{title}</h6>
      <Links links={content} path={path} />
    </div>
  );
};

export const Footer = (props) => {
  const { site, ...content } = props.data;
  const socials = site && site.siteMetadata && site.siteMetadata.socials;

  console.log('CONTENT', content);

  return (
    <footer className="footer">
      <Wrapper className="footer-columns">
        <div className="footer-column">
          <Logo />
          <SocialLinks links={socials} />
        </div>
        <FooterColumn title="Quick Links" content={content.page} />
        <FooterColumn title="Services" path="service" content={content.service} />
        <FooterColumn title="Locations" content={content.location} />
      </Wrapper>
    </footer>
  );
};
