import React, { useState } from 'react';
import { Section, Wrapper, Heading, TextField, TextArea, Image } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import { MapForm } from './MapForm';
import './Form.scss';

// List required fields for each steps
const fieldsInit = {
  fullname: { required: true, value: null },
  company: { required: false, value: null },
  email: { required: true, value: null },
  phone: { required: true, value: null },
  message: { required: false, value: null },
};

const ContactForm = (props) => {
  const [fields, setFields] = useState(fieldsInit);
  const { submit } = props;

  // Set Field when onChange
  const setField = (name, value, error) => {
    setFields({ ...fields, [name]: { ...fields[name], value, error } });
  };

  // Check if form can be submitted
  const readyToSubmit = Object.keys(fields).every((fieldName) => {
    const field = fields[fieldName];
    if (field.error || (field.required && !field.value)) return false;
    return true;
  });

  return (
    <form className="contact-form" name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <TextField className="form-half" name="fullname" label="Full name" onChange={setField} fields={fields} />
      <TextField className="form-half" name="company" label="Company name" onChange={setField} fields={fields} />
      <TextField className="form-half" name="email" label="Email" onChange={setField} fields={fields} />
      <TextField className="form-full" name="phone" label="Phone" onChange={setField} fields={fields} />
      <TextArea className="form-full" name="message" label="Message" onChange={setField} fields={fields} />
      <button type="submit" className="link button primary lg" disabled={!readyToSubmit}>
        {submit}
      </button>
    </form>
  );
};

export const Form = ({ data }) => {
  const { title, subtitle, submit } = data;

  const content = useStaticQuery(graphql`
    query CoordinatesQuery {
      site {
        siteMetadata {
          marker
        }
      }
      location: allPrismicLocation {
        nodes {
          uid
          type
          data {
            title {
              text
            }
            location {
              coordinates {
                lat: latitude
                lng: longitude
              }
              name
            }
          }
        }
      }
      project: allPrismicProject {
        nodes {
          uid
          type
          data {
            title {
              text
            }
            location {
              coordinates {
                lat: latitude
                lng: longitude
              }
              name
            }
          }
        }
      }
    }
  `);

  const hasLocations = content.location.nodes.length > 0;

  return (
    <Section id="contact" className="form">
      <div className="form-map">
        {hasLocations && <MapForm content={content} />}
        {!hasLocations && <div className="form-image" style={{ backgroundImage: 'url(/image.jpg)' }} />}
      </div>

      <Wrapper className="form-contact">
        <Heading className="vertical" title={title} subtitle={subtitle} />
        <ContactForm submit={submit} />
      </Wrapper>
    </Section>
  );
};
