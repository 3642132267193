import React from 'react';
import { Section, Wrapper } from 'components';
import './Text.scss';

export const Text = ({ data }) => {
  const { title, subtitle, body } = data;
  return (
    <Section className="text">
      <Wrapper>
        {title && <h2>{title.text}</h2>}
        {subtitle && <p className="subtitle">{subtitle.text}</p>}
        {body && <div className="text-body" dangerouslySetInnerHTML={{ __html: body.html }} />}
      </Wrapper>
    </Section>
  );
};
