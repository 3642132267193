import React, { useState } from 'react';
import { Marker, MarkerClusterer, InfoWindow } from '@react-google-maps/api';
import { Maps, Link } from 'components';

export const MapForm = (props) => {
  // Query links
  const { content } = props;

  const locationIcon = () => {
    if (typeof window === 'undefined') return null;
    const gmaps = window && window.google && window.google.maps;
    const locationIconPath = content && content.site && content.site.siteMetadata && content.site.siteMetadata.marker;
    return {
      url: locationIconPath,
      scaledSize: new gmaps.Size(45, 45),
    };
  };

  const projectIcon = {
    path: 'M 0, 0 m -10, 0 a 10, 10 0 1, 0 20, 0 a 10, 10 0 1, 0 -20, 0',
    fillColor: '#008d2e',
    fillOpacity: 0.7,
    scale: 0.8,
    strokeColor: '#008d2e',
    strokeWeight: 2,
    strokeOpacity: 1,
  };

  const marker = (uid, id, type, coordinates, title, name, clusterer) => (
    <Marker
      icon={type === 'project' ? projectIcon : locationIcon()}
      key={id}
      position={coordinates}
      clusterer={clusterer}
      onClick={() => toggleInfo(id)}
    >
      {info === id && (
        <InfoWindow position={coordinates} onCloseClick={() => toggleInfo()}>
          <div className="marker">
            <div className="marker-title">{title.text}</div>
            <div className="marker-location">{name}</div>
            <div className="marker-action">
              <Link className="button xs primary" to={type === 'location' ? `/${uid}` : `/${type}/${uid}`}>
                Learn more
              </Link>
            </div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );

  // Markers Generator
  const [maps, setMaps] = useState(null);
  const [info, toggleInfo] = useState();
  const markers = ({ nodes }, clusterer) => {
    if (typeof window === 'undefined') return null;
    const gmaps = window && window.google && window.google.maps;
    if (!gmaps || !maps) return;
    const bounds = new gmaps.LatLngBounds();
    const markersIcons = nodes.reduce((items, item) => {
      const { uid, type, data } = item;
      const { title, location } = data;
      location.map(({ coordinates, name }, index) => {
        const id = uid + index;
        const { lat, lng } = coordinates;
        if (!lat || !lng) return items;
        if (bounds && gmaps) bounds.extend(new gmaps.LatLng(lat, lng));
        items.push(marker(uid, id, type, coordinates, title, name, clusterer));
      });
      return items;
    }, []);
    maps.fitBounds(bounds);
    maps.setCenter(bounds.getCenter());
    return markersIcons;
  };

  return (
    <Maps mapsRef={(ref) => setMaps(ref)}>
      <MarkerClusterer>{(clusterer) => markers(content.project, clusterer)}</MarkerClusterer>
      {markers(content.location)}
    </Maps>
  );
};
